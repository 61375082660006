
export interface PaginationOptions {
    pageNumber: number;
    countPerPage: number;
}

export interface PaginationOptionsResults<T> extends PaginationOptions{
    items: T[];
    total: number;
}

export function objectToUrl (obj: any): string {
    return Object.entries(obj).map(e => e.join('=')).join('&');
}
