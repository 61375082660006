import antdData from 'antd/lib/locale-provider/uk_UA';
import { LocaleModel, Localization } from './model';

const messages: LocaleModel = {
  "topBar.profileMenu.hello": "Привіт",
  "topBar.profileMenu.role": "Роль",
  "topBar.profileMenu.email": "Електронна пошта",
  "topBar.profileMenu.phone": "Телефон",
  "topBar.profileMenu.editProfile": "Редагувати профіль",
  "topBar.profileMenu.logout": "Вийти",

  "required": "Обов'язково",
  "create": "Створити",
  "loading": "Завантаження",
  "google": "Google",

  "linkManagement.page.title": "Посилання",
  "linkManagement.page.create": "Створити",

  "linkManagement.table.clientName": "Ім'я",
  "linkManagement.table.clientPhone": "Телефон",
  "linkManagement.table.used": "Використовується",
  "linkManagement.table.yes": "Так",
  "linkManagement.table.no": "Ні",
  "linkManagement.table.linkId": "Посилання",
  "linkManagement.table.calendarTitle": "Заголовок календаря:",
  "linkManagement.table.sessionDuration": "Тривалість сесії:",
  "linkManagement.table.privateNotes": "Приватні замітки:",
  "linkManagement.table.showDatesTo": "Показувати дати до:",
  "linkManagement.table.schedule": "Розклад:",

  "linkManagement.createLinkModal.validateFailed": "Помилка перевірки",
  "linkManagement.createLinkModal.title": "Заголовок",
  "linkManagement.createLinkModal.privateNotes": "Приватні замітки",
  "linkManagement.createLinkModal.showDatesTo": "Показувати дати до",

  "linkManagement.createLinkModal.titleForCalendar": "Заголовок для календаря",
  "linkManagement.createLinkModal.clientName": "Ім'я клієнта",
  "linkManagement.createLinkModal.clientPhone": "Телефон клієнта",
  "linkManagement.createLinkModal.employee": "Співробітник",
  "linkManagement.createLinkModal.sessionDuration": "Тривалість сесії (хвилини)",
  "linkManagement.createLinkModal.min": "хв.",
  "linkManagement.createLinkModal.30min": "30 хв.",
  "linkManagement.createLinkModal.60min": "1 год.",
  "linkManagement.createLinkModal.90min": "1 год. 30 хв.",
  "linkManagement.createLinkModal.create": "Створити",
  "linkManagement.createLinkModal.addLink": "Додати посилання",

  "employee.createEmployeeModal.title": "Співробітник",
  "employee.createEmployeeModal.addEmployee": "Додати співробітника",
  "employee.createEmployeeModal.create": "Додати співробітника",
  "employee.createEmployeeModal.name": "Ім'я",
  "employee.createEmployeeModal.action": "Дія",

  "linkPreview.scheduling": "Планування...",
  "linkPreview.hello": "Привіт",
  "linkPreview.chooseDate": "Виберіть дату",
  "linkPreview.chooseTime": "Виберіть час",
  "linkPreview.done": "Готово",
  "linkPreview.ok": "Ви успішно запланували",
  "linkPreview.error": "Щось пішло не так",

  'scheduleConfig.title': 'Посилання',
  'scheduleConfig.defaultLinkTitle': 'Заголовок за замовчуванням',
  'scheduleConfig.workTime': 'Робочий час',
  'scheduleConfig.breakTime': 'Час перерви',
  'scheduleConfig.sessionDuration': 'Тривалість сесії',
  'scheduleConfig.durationBetweenSessions': 'Тривалість між сесіями',
  'scheduleConfig.isNationalWeekendsAreNotWorkingDays': 'Неробочі дні - національні вихідні',
  'scheduleConfig.save': 'Зберегти',

  "weekend.title": "Вихідні",
  "weekend.addWeekend": "Додати вихідний",
  "weekend.modal.title": "Заголовок",
  "weekend.modal.date": "Дата",
  "weekend.modal.action": "Дія",

  "user.login.title": "Ласкаво просимо до вашої угоди",
  "user.login.auth": "Авторизація",

  "404.title": "Сторінку не знайдено",
  "404.description": "Сторінка застаріла, видалена або взагалі не існує",

  'menu.home': 'Головна',
  'menu.scheduleConfig': 'Налаштування розкладу',
  'menu.linkManagement': 'Керування посиланнями',
  'menu.employees': 'Працівники',
  'menu.weekends': 'Вихідні',
}
  ;

export default {
  locale: 'uk-UA',
  antdData,
  messages,
} as Localization;
