import React, { Dispatch } from 'react';
import {
  // Menu, 
  // Dropdown, 
  Tag
} from 'antd';
import { connect } from 'react-redux';
// import styles from './style.module.scss';
import { SettingStore } from 'redux/settings';
import { LocaleSettings } from 'components/LayoutComponents/Localization';
import Actions, { SettingItem } from 'redux/settings/duck-reducers';
import { AnyAction } from 'redux';

const { CheckableTag } = Tag;

interface Props {
  changeSetting: (setting: SettingItem) => void;
  settings: LocaleSettings;
}

export const LanguageSelector = (props: Props) => {
  const {
    settings: { locale },
    changeSetting,
  } = props;

  const changeLang = (checked: boolean, lang: string) => {
    if (checked)
      changeSetting({
        setting: 'locale',
        value: lang,
      });
  };

  // const language = locale.substring(0, 2);

  return (
    <>
      <CheckableTag key="en-US" checked={locale === 'en-US'} onChange={checked => changeLang(checked, "en-US")}>
        <span role="img" aria-label="English" className="mr-2">
          🇬🇧
        </span>
        English
      </CheckableTag>
      {/* <CheckableTag key="uk-UA" checked={locale === 'uk-UA'}  onChange={checked => changeLang(checked, "uk-UA")}>
        <span role="img" aria-label="Українська" className="mr-2">
          🇺🇦
        </span>
        Українська
      </CheckableTag> */}
      <CheckableTag key="es-ES" checked={locale === 'es-ES'} onChange={checked => changeLang(checked, "es-ES")}>
        <span role="img" aria-label="Espanol" className="mr-2">
          🇪🇸
        </span>
        Espanol
      </CheckableTag>
    </>
    // <Dropdown overlay={langMenu} trigger={['click']}>
    //   <div className={styles.dropdown}>
    //     <strong className="text-uppercase">{language}</strong>
    //   </div>
    // </Dropdown>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  changeSetting: (setting: SettingItem) =>
    dispatch(Actions.changeSetting(setting)),
});

const mapStateToProps = ({ settingState }: SettingStore) =>
({
  settings: { locale: settingState.settings.locale },
} as Props);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageSelector);
