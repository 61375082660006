import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getTokenByAuthCodeRequest,
  currentAccount,
  logout,
  PostUserLoginRequest,
  UserProfileVm,
} from '../../services/users';
import { ApiCallParams } from '../../services/baseService';
import UserReducers from './duck-reducers';

const { setCurrentUser } = UserReducers;

export function* getTokenByAuthCode(action: Action<PostUserLoginRequest>) {
  try {
    const payload: ApiCallParams<PostUserLoginRequest> = {
      payload: action.payload as PostUserLoginRequest,
    };

    const success = (yield call(getTokenByAuthCodeRequest, payload)) as boolean;
    if (success) {
      notification.success({
        message: 'Authorized',
        description: 'You have came in!',
      });

      yield getCurrentUserSaga();
    }
  } catch (e) {
    console.error('userSaga', e);
  }
}

export function* getCurrentUserSaga() {
  try {
    const data = (yield call(currentAccount)) as UserProfileVm;
    if (data) {
      yield put(setCurrentUser(data));
    }
  } catch (e) {
    console.error('userSaga', e);
  }
}

export function* logoutSaga() {
  try {
    yield call(logout);
  } catch (e) {
    console.error('userSaga', e);
  }
}
