const actions = {
  SET_STATE: 'user/SET_STATE',
  REMOVE_CURRENT_USER: 'user/REMOVE_CURRENT_USER',
  GET_TOKEN_BY_AUTHCODE: 'user/GET_TOKEN_BY_AUTHCODE',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SET_CURRENT_DEPARTMENT: 'user/SET_CURRENT_DEPARTMENT',
};

export default actions;
