const actions = {
  SET_STATE: 'weekend/SET_STATE',
  SET_CURRENT_WEEKEND: 'weekend/SET_CURRENT_WEEKENDS',

  LOAD_WEEKENDS: 'weekend/LOAD_WEEKENDS',

  CREATE_WEEKEND: 'weekend/CREATE_WEEKEND',
  SET_SUCCESS_WEEKEND: 'weekend/SET_SUCCESS_WEEKEND',
  SET_ERROR_WEEKEND: 'weekend/SET_ERROR_WEEKEND',


  ADD_WEEKEND: 'weekend/ADD_WEEKEND',
  REMOVE_WEEKEND: 'weekend/REMOVE_WEEKEND',

  OPEN_ADD_WEEKEND_MODAL: 'weekend/OPEN_ADD_WEEKEND_MODAL',
  CLOSE_ADD_WEEKEND_MODAL: 'weekend/CLOSE_ADD_WEEKEND_MODAL',

};

export default actions;
