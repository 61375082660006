import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as schedule from '../../services/schedule';
import ManagementReducers from './duck-reducers';

const {
  errorSchedule,
  successSchedule
} = ManagementReducers;


export function* createScheduleSaga(action: Action<schedule.ScheduleRequestVm>) {
  try {
    const result: boolean = yield call(schedule.schedule, action.payload!);
    if (result) {
      yield put(successSchedule());
    }
  } catch (e) {
    console.error('scheduleSaga', e);
    yield put(errorSchedule());
  }
}


export const Saga = {
  createScheduleSaga,
};
