import { createDuck } from 'redux-typed-ducks';
import actions from './actions';
import { MenuState } from './store';

const setState = createDuck<MenuState, MenuState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

export default {
  setState,
};
