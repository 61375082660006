import Actions, { SettingItem } from 'redux/settings/duck-reducers';
import React, { useEffect, useState, Dispatch } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Menu, } from 'antd';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import store from 'store';
import styles from '../style.module.scss';
import { SettingStore } from 'redux/settings';
import { MenuStore } from 'redux/menu';
import { MenuVm } from 'services/menu';
import { FormattedMessage } from 'react-intl';

interface Props extends RouteComponentProps<any> {
  isLightTheme: boolean;
  isVertical: boolean;
  menuData: MenuVm[];
  changeSetting: (setting: SettingItem) => void;
}

export const MenuTop = (props: Props) => {
  const { changeSetting } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    store.get('app.menu.selectedKeys') || [],
  );

  useEffect(() => {
    processSelectedKeys(props);
  }, []);

  const processSelectedKeys = (props: Props) => {
    // const { menuData } = props;

    const flattenItems = (items: MenuVm[], key: string): MenuVm[] =>
      items.reduce((flattenedItems: MenuVm[], item) => {
        flattenedItems.push(item);

        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }

        return flattenedItems;
      }, []);

    // const selectedItem = [].find(flattenItems(menuData, 'children'), [
    //   'url',
    //   props.location.pathname,
    // ]);

    setSelectedKeys(/*selectedItem ? [selectedItem.key] : */[]);
  };

  const handleClick = (e: any) => {
    store.set('app.menu.selectedKeys', [e.key]);
    changeSetting({
      setting: 'isMobileMenuOpen',
      value: false,
    });
    setSelectedKeys([e.key]);
  };

  const generateMenuItems = () => {
    const { menuData = [] } = props;

    const generateItem = (item: MenuVm) => {
      const { key,  url, icon, disabled } = item;

      // if (item.divider) {
      //   return {
      //     type: "divider",
      //   };
      // }
      if (item.url) {
        return {
          key: key,
          label: <Link to={url} className="font-weight-bold">
            {icon && <span className={`${icon} ${styles.icon}`} />}
            <span className={styles.title} ><FormattedMessage id={`menu.${key}`} /></span>
          </Link>
        };
      }
      return {
        key: key,
        disabled: disabled,
        label: <span className={styles.title}><FormattedMessage id={`menu.${key}`} /></span>
      };
    };

    return menuData.map(menuItem => {
      return generateItem(menuItem);
    });
  };

  return (
    <Menu
      items={generateMenuItems()}
      onClick={handleClick}
      selectedKeys={selectedKeys}
      mode={props.isVertical ? "vertical" : "horizontal"}
    />
  );
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  changeSetting: (setting: SettingItem) =>
    dispatch(Actions.changeSetting(setting)),
});

const mapStateToProps = ({
  settingState,
  menuState,
}: SettingStore & MenuStore) =>
({
  isVertical: settingState.settings.isMobileView,
  menuData: menuState.menuTopData,
  isLightTheme: settingState.settings.isLightTheme,
} as Props);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuTop));
