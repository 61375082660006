const actions = {
  SET_STATE: 'personalWeekend/SET_STATE',
  SET_CURRENT_PERSONAL_WEEKEND: 'personalWeekend/SET_CURRENT_PERSONAL_WEEKENDS',

  LOAD_PERSONAL_WEEKENDS: 'personalWeekend/LOAD_PERSONAL_WEEKENDS',

  CREATE_PERSONAL_WEEKEND: 'personalWeekend/CREATE_PERSONAL_WEEKEND',
  SET_SUCCESS_PERSONAL_WEEKEND: 'personalWeekend/SET_SUCCESS_PERSONAL_WEEKEND',
  SET_ERROR_PERSONAL_WEEKEND: 'personalWeekend/SET_ERROR_PERSONAL_WEEKEND',


  ADD_PERSONAL_WEEKEND: 'personalWeekend/ADD_PERSONAL_WEEKEND',
  REMOVE_PERSONAL_WEEKEND: 'personalWeekend/REMOVE_PERSONAL_WEEKEND',

  OPEN_ADD_PERSONAL_WEEKEND_MODAL: 'personalWeekend/OPEN_ADD_PERSONAL_WEEKEND_MODAL',
  CLOSE_ADD_PERSONAL_WEEKEND_MODAL: 'personalWeekend/CLOSE_ADD_PERSONAL_WEEKEND_MODAL',

};

export default actions;
