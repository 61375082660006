import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as scheduleConfig from '../../services/scheduleConfig';
import ScheduleConfigReducers from './duck-reducers';

const { setScheduleConfig } = ScheduleConfigReducers;

export function* getUserConfig() {
  try {
    const data = (yield call(scheduleConfig.getUserConfig)) as scheduleConfig.ScheduleConfigVm;

    if (data)
      yield put(setScheduleConfig(data));
  } catch (e) {
    console.error('scheduleSaga', e);
  }
}

export function* updateUserConfig(action: Action<scheduleConfig.ScheduleConfigVm>) {
  try {
    const data: number = yield call(scheduleConfig.updateUserConfig, action.payload!);
    if (data)
      yield getUserConfig();
  } catch (e) {
    console.error('scheduleSaga', e);
  }
}


export const ScheduleConfigSaga = {
  getUserConfig,
  updateUserConfig,
}
