import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Avatar, Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { UserStore } from 'redux/user';
import { AnyAction } from 'redux';
import Actions from 'redux/user/duck-reducers';
import { UserProfileVm } from 'services/users';
import LanguageSelector from '../LanguageSelector';

interface Props {
  logoutUser: () => void;
  user: UserProfileVm;
}

export const ProfileMenu = (props: Props) => {
  const { logoutUser, user } = props;

  const menuItems = [
    {
      key: 'user-name',
      label: <>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />,{' '}
          {user.name || 'Anonymous'}
        </strong>
      </>
    },
    {
      key: 'user-email',
      label: <div>
        <strong>
          <FormattedMessage id="topBar.profileMenu.email" />:{' '}
        </strong>
        {user.email}
        <br />
      </div>
    },
    {
      key: "user-lang",
      label: <LanguageSelector />
    },
    {
      key: "user-logout",
      label: <a href="#" onClick={logoutUser}>
        <i className={`${styles.menuIcon} icmn-exit`} />
        <FormattedMessage id="topBar.profileMenu.logout" />
      </a>
    }
  ];

  const title =
    <div className={styles.dropdown}>
      <Avatar
        className={styles.avatar}
        shape="square"
        size="large"
        src={user.picture}
        icon={<UserOutlined translate={undefined} />}
      />
    </div>;

  return (
    <Dropdown menu={{ items: menuItems }}>
      {title}
    </Dropdown>
  );
};

const mapStateToProps = ({ userState }: UserStore) =>
({
  user: userState.user,
} as Props);

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  logoutUser: () => dispatch(Actions.logoutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileMenu);
