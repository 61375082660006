import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import LinkManagementActions from 'redux/linkManagement/actions';
import {
  getAllClientLinksByOptions,
  getLinkPreview,
  createClientLink,
  removeCientLink,
} from 'redux/linkManagement/saga';
import LinkManagementReducers from 'redux/linkManagement/duck-reducers';
import { ClientLinksState, CClientLinksBase as ClientLinksBase } from './store';
import { ClientLinkVm, LinkPreviewVm } from 'services/linkManagement';

export * from './store';

const initialState: ClientLinksState = {
  clientLinks: [],
  linkPreview: {} as LinkPreviewVm,
  openCreateModal: false,
  creatingClientLink: {} as ClientLinkVm,
  loading: false,
};

export const Actions = {
  ...LinkManagementActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(LinkManagementReducers.getClientLinksByOptions.actionType, getAllClientLinksByOptions),
    takeEvery(LinkManagementReducers.loadLinkPreview.actionType, getLinkPreview),
    takeEvery(LinkManagementReducers.createClientLink.actionType, createClientLink),
    takeEvery(LinkManagementReducers.removeClientLink.actionType, removeCientLink),
  ]);
}

export interface ClientLinksReducerStore extends ClientLinksBase {
  clientLinksState: Reducer<ClientLinksState, any>;
}

export const clientLinksReducer: ClientLinksReducerStore = {
  clientLinksState: createReducer(LinkManagementReducers, initialState),
};
