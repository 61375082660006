import { createDuck } from 'redux-typed-ducks/dist';
import { ClientLinkVm } from 'services/linkManagement';
import actions from './actions';
import { ScheduleState } from './store';

interface EmptyPayload { }

const setState = createDuck<ScheduleState, ScheduleState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const createSchedule = createDuck<ScheduleState, EmptyPayload>(
  actions.CREATE_SCHEDULE,
  state => ({
    ...state,
    scheduling: true,
  }),
);

const successSchedule = createDuck<ScheduleState, EmptyPayload>(
  actions.SET_SUCCESS_SCHEDULE,
  state => ({
    ...state,
    scheduling: false,
    schedulingSuccess: true
  }),
);

const errorSchedule = createDuck<ScheduleState, EmptyPayload>(
  actions.SET_ERROR_SCHEDULE,
  state => ({
    ...state,
    scheduling: false,
    schedulingSuccess: false
  }),
);

const openCreateClientLinkModal = createDuck<ScheduleState, EmptyPayload>(
  actions.OPEN_ADD_SCHEDULE_MODAL,
  state => ({
    ...state,
    openCreateModal: true,
    creatingClientLink: {
      id: 0,
      title: '',
      privateNotes: '',
      showDatesTo: '',
      userId: 0,
    },
  }),
);

const closeCreateClientLinkModal = createDuck<ScheduleState, EmptyPayload>(
  actions.CLOSE_ADD_SCHEDULE_MODAL,
  state => ({
    ...state,
    openCreateModal: false,
    creatingClientLink: {
      id: 0,
      title: '',
      privateNotes: '',
      showDatesTo: '',
      userId: 0,
    },
  }),
);

const createClientLink = createDuck<ClientLinkVm, EmptyPayload>(
  actions.CREATE_CLIENT_LINK,
  state => ({
    ...state,
  }),
);

const removeClientLink = createDuck<ClientLinkVm, EmptyPayload>(
  actions.REMOVE_SCHEDULE,
  state => ({
    ...state,
  }),
);

export default {
  setState,
  createSchedule,
  successSchedule,
  errorSchedule,
  openCreateClientLinkModal,
  closeCreateClientLinkModal,
  createClientLink,
  removeClientLink,
};
