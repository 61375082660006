import { objectToUrl } from 'services/basic';
import requestToApi, { api } from '../baseService';
import { ClientLinkPaginationOptions, ClientLinkVm, LinkPreviewVm } from './domain';


export async function getLinkByLinkId(id: string): Promise<LinkPreviewVm> {
  const response = await requestToApi<LinkPreviewVm>(
    '/api/ClientLinks/get-by-link-id/' + id,
    'get',
    false,
  );

  return response!;
}

export async function getByOptions(options: ClientLinkPaginationOptions): Promise<ClientLinkVm[]> {
  const response = await api.apiGet<ClientLinkVm[]>(
    '/api/ClientLinks/get-by-options?' + objectToUrl(options),
  );

  return response.data;
}

// base crud

export async function getAll(): Promise<ClientLinkVm[]> {
  const response = await api.apiGet<ClientLinkVm[]>('/api/ClientLinks/GetAll');

  return response.data;
}

export async function get(id: number): Promise<ClientLinkVm> {
  const response = await api.apiGet<ClientLinkVm>(`/api/ClientLinks/${id}`);

  return response.data;
}

export async function put(model: ClientLinkVm): Promise<ClientLinkVm> {
  const response = await api.apiPut<ClientLinkVm>(`/api/ClientLinks/`, {
    payload: model,
  });

  return response.data;
}

export async function post(model: ClientLinkVm): Promise<number> {
  const response = await api.apiPost<number>(`/api/ClientLinks/`, {
    payload: model,
  });

  return response.data;
}

export async function deleteById(id: number): Promise<void> {
  await api.apiDelete(`/api/ClientLinks/${id}`);
}
