import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import ManagementActions from 'redux/schedule/actions';
import {
  createEmployeeSaga,
  removeEmployeeSaga
} from 'redux/employee/saga';
import ManagementReducers from 'redux/employee/duck-reducers';
import { EmployeeState, EmployeeBase } from './store';
import { getAllEmployeesByOptions, getEmployeesWithSchedules } from './saga';
import { EmployeeVm } from 'services/employee';

export * from './store';

const initialState: EmployeeState = {
  employees: [],
  employeesWithSchedules: [],
  totalEmployees: 0,
  currentPage: 0,
  openCreateModal: false,
  loadingEmployeesWithSchedules: false,
  creating: false,
  creatingSuccess: false,
  creatingObject: {} as EmployeeVm,
  loading: false,
};

export const Actions = {
  ...ManagementActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(ManagementReducers.create.actionType, createEmployeeSaga),
    takeEvery(ManagementReducers.remove.actionType, removeEmployeeSaga),
    takeEvery(ManagementReducers.getEmployeesByOptions.actionType, getAllEmployeesByOptions),
    takeEvery(ManagementReducers.getEmployeesWithSchedules.actionType, getEmployeesWithSchedules),
  ]);
}

export interface EmployeesReducerStore extends EmployeeBase {
  employeeState: Reducer<EmployeeState, any>;
}

export const employeeReducer: EmployeesReducerStore = {
  employeeState: createReducer(ManagementReducers, initialState),
};
