import { objectToUrl } from 'services/basic';
import { api } from '../baseService';
import { WeekendPaginationOptions, WeekendVm } from './domain';

export async function getByOptions(options: WeekendPaginationOptions): Promise<WeekendVm[]> {
  const response = await api.apiGet<WeekendVm[]>(
    '/api/Weekends/get-by-options?' + objectToUrl(options),
  );

  return response.data;
}

// base crud

export async function getAll(): Promise<WeekendVm[]> {
  const response = await api.apiGet<WeekendVm[]>('/api/Weekends/GetAll');

  return response.data;
}

export async function get(id: number): Promise<WeekendVm> {
  const response = await api.apiGet<WeekendVm>(`/api/Weekends/${id}`);

  return response.data;
}

export async function put(model: WeekendVm): Promise<WeekendVm> {
  const response = await api.apiPut<WeekendVm>(`/api/Weekends/`, {
    payload: model,
  });

  return response.data;
}

export async function post(model: WeekendVm): Promise<number> {
  const response = await api.apiPost<number>(`/api/Weekends/`, {
    payload: model,
  });

  return response.data;
}

export async function deleteById(id: number): Promise<void> {
  await api.apiDelete(`/api/Weekends/${id}`);
}
