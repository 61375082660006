import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import Loader from 'components/LayoutComponents/Loader';
import IndexLayout from 'layouts';
import { NotFound as NotFoundPage } from 'pages/404';

const loadable = (loader: any) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const routes = [
  // System Pages
  {
    path: "/",
    component: loadable(() => import('pages/mySchedules')),
    exact: true,
  },
  {
    path: "/scheduleConfig",
    component: loadable(() => import('pages/scheduleConfig')),
    exact: true,
  },
  {
    path: "/linkManagement",
    component: loadable(() => import('pages/linkManagement')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/login/:authCode',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/l/:linkId',
    component: loadable(() => import('pages/linkPreview')),
    exact: true,
  },
  {
    path: '/employees',
    component: loadable(() => import('pages/employees')),
  },
  {
    path: '/weekends',
    component: loadable(() => import('pages/weekend')),
  },
];

interface Props {
  history: any;
}

export const Router = (props: Props) => {
  const { history } = props;

  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          {routes.map(route => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  );
};

export default Router;
