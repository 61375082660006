import axios from 'axios';
import { notification } from 'antd';
import configuration from './config';
import { userIsAuthorize } from './JWTservise';

export class ApiResponse<T = any> {
  constructor(public data: T) { }
}

type Payload = object | number | string | null;

export interface EndpointCallParams<P extends Payload> {
  payload: P;
}

export interface ApiCallParams<P extends Payload = any> extends EndpointCallParams<P> {
  isAbsoluteUrl?: boolean; // TODO: Find a better way to handle absolute urls
}

export default async function requestToApi<T = any>(
  path = '',
  httpMethod: 'post' | 'put' | 'patch' | 'get' | 'delete' = 'get',
  needAuth = true,
  body = {},
  isFile = false,
): Promise<T | null> {
  const contentType = isFile ? 'multipart/form-data' : 'application/json';

  const myHeaders = new Headers({ 'Content-Type': contentType });

  const { apiTokenStorageKey, apiUrl } = configuration;

  const token = localStorage.getItem(apiTokenStorageKey);
  const url = `${apiUrl}${path}`;

  let requestResult = null;

  if (needAuth) {
    if (await userIsAuthorize()) {
      if (token && token !== '') {
        myHeaders.append('Authorization', `Bearer ${token}`);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  let myInit: RequestInit = { method: httpMethod, headers: myHeaders, cache: 'default' };
  const props = isFile ? { data: body } : { body: JSON.stringify(body) };

  if (httpMethod.toUpperCase() !== 'GET') {
    myInit = { ...myInit, ...props };
  }

  let responseStatus: number = 0;

  await fetch(url, myInit)
    .then(async response => {
      const { status } = response;
      responseStatus = status;
      requestResult = await parseResponse(response);
    })
    .catch(error => {
      if (responseStatus === 200) {
        requestResult = true;
      } else {
        notification.warning({
          message: error.code,
          description: error.message,
        });
      }
      requestResult = false;
    });

  return requestResult;
}

export async function requestTo3tdApi<T = any>(
  path = '',
  httpMethod: 'post' | 'put' | 'patch' | 'get' | 'delete' = 'get',
  body = {},
  isFile = false,
): Promise<T | null> {
  const contentType = isFile ? 'multipart/form-data' : 'application/json';

  const myHeaders = new Headers({ 'Content-Type': contentType });

  const url = `${path}`;

  let requestResult = null;

  let myInit: RequestInit = { method: httpMethod, headers: myHeaders, cache: 'default' };
  const props = isFile ? { data: body } : { body: JSON.stringify(body) };

  if (httpMethod.toUpperCase() !== 'GET') {
    myInit = { ...myInit, ...props };
  }

  let responseStatus: number = 0;

  await fetch(url, myInit)
    .then(async response => {
      const { status } = response;
      responseStatus = status;
      requestResult = await parseResponse(response);
    })
    .catch(error => {
      if (responseStatus === 200) {
        requestResult = true;
      } else {
        notification.warning({
          message: error.code,
          description: error.message,
        });
      }
      requestResult = false;
    });

  return requestResult;
}

export async function apiGet<T = any>(url: string): Promise<ApiResponse<T>> {
  const result = await requestToApi(url, 'get', true);
  return new ApiResponse(result);
}
export async function apiPost<T = any>(url: string, params?: ApiCallParams): Promise<ApiResponse<T>> {
  const result = await requestToApi(url, 'post', true, params?.payload);
  return new ApiResponse(result);
}
export async function apiPostFile<T = any>(url: string, params: ApiCallParams): Promise<ApiResponse<T>> {

  const { apiTokenStorageKey, apiUrl } = configuration;

  const formData = new FormData();
  formData.set('File', params.payload);

  const token = localStorage.getItem(apiTokenStorageKey);
  const uploadURL = `${apiUrl}${url}`;

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    },
  };

  let result: any = null;

  await axios.post(uploadURL, formData, config)
    .catch((error: any) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    })
    .then((response: any) => {
      result = response?.data;
    });

  return new ApiResponse(result);
}
async function apiPut<T = any>(url: string, params?: ApiCallParams): Promise<ApiResponse<T>> {
  const result = await requestToApi(url, 'put', true, params?.payload);
  return new ApiResponse(result);
}

async function apiDelete(url: string): Promise<ApiResponse<any>> {
  const result = await requestToApi(url, 'delete', true);
  return new ApiResponse(result);
}
async function apiPatch(url: string, params?: ApiCallParams): Promise<ApiResponse<any>> {
  const result = await requestToApi(url, 'patch', true, params?.payload);
  return new ApiResponse(result);
}

export const api = {
  apiGet,
  apiPost,
  apiPostFile,
  apiPut,
  apiPatch,
  apiDelete,
};

async function parseResponse<T = any>(response: Response): Promise<T | null | boolean> {
  switch (response.status) {
    case 200:
    case 201: {
      return response.json();
    }

    case 202:
    case 204: {
      return true;
    }

    case 400: {
      const requestError = await response.json();

      notification.error({
        message: ` ${requestError.error || requestError.Error || 'Error'}`,
        description: requestError.message || requestError.Message,
      });
      break;
    }
    case 401: {
      notification.error({
        message: 'No authorize',
        description: 'Please sign in!',
      });
      break;
    }

    case 404: {
      window.location.replace("/404")
      break;
    }

    case 500: {
      const requestError = await response.json();

      notification.error({
        message: `${requestError.StatusCode} - ${requestError.Message}`,
        description: requestError.Error,
      });
      break;
    }

    default: {
      notification.error({
        message: 'Error',
        description: 'Not catched error!',
      });
      return false;
    }
  }
  return false;
}
