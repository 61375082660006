import { createDuck } from 'redux-typed-ducks/dist';
import { UserProfileVm } from 'services/users';
import actions from './actions';
import { UserState } from './store';

export interface UserLoginVm {
  authCode: string;
}

export interface UserRegisterVm extends UserLoginVm {
  phoneNumber: string;
  rePassword: string;
}

const getCurrentUser = createDuck<UserState, UserProfileVm>(
  actions.LOAD_CURRENT_ACCOUNT,
  state => ({
    ...state,
    loading: true,
  }),
);

const setCurrentUser = createDuck<UserState, UserProfileVm>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...({
      user: {
        ...state.user,
        ...payload,
        ...{
          authorized: true,
        },
      },
    } as UserState),
  }),
);

const getTokenByAuthCode = createDuck<UserState, UserLoginVm>(actions.GET_TOKEN_BY_AUTHCODE, state => ({
  ...state,
}));

const logoutUser = createDuck<UserState, UserState>(actions.LOGOUT, state => ({
  ...state,
  user: {
    name: '',
    role: '',
    email: '',
    authorized: false,
  } as UserProfileVm,
}));

const setCurrentDepartment = createDuck<UserState, number>(
  actions.SET_CURRENT_DEPARTMENT,
  (state, payload) => ({
    ...state,
    ...({
      user: {
        ...state.user,
        ...({
          departmentId: payload,
        } as UserProfileVm),
      },
    } as UserState),
  }),
);

export default {
  setCurrentUser,
  getCurrentUser,
  getTokenByAuthCode,
  logoutUser,
  setCurrentDepartment,
};
