import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import ScheduleConfigActions from 'redux/scheduleConfig/actions';
import {
  getUserConfig,
  updateUserConfig,
} from 'redux/scheduleConfig/saga';
import ScheduleConfig from 'redux/scheduleConfig/duck-reducers';
import { ScheduleConfigState, ScheduleConfigStoreBase } from './store';
import { ScheduleConfigVm } from 'services/scheduleConfig';

export * from './store';

const initialState: ScheduleConfigState = {
  scheduleConfig: {} as ScheduleConfigVm,
  loading: false,
};

export const Actions = {
  ...ScheduleConfigActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(ScheduleConfig.loadScheduleConfig.actionType, getUserConfig),
    takeEvery(ScheduleConfig.saveScheduleConfig.actionType, updateUserConfig),
  ]);
}

export interface ScheduleConfigReducerStore extends ScheduleConfigStoreBase {
  scheduleConfigState: Reducer<ScheduleConfigState, any>;
}

export const scheduleConfigReducer: ScheduleConfigReducerStore = {
  scheduleConfigState: createReducer(ScheduleConfig, initialState),
};
