import { createDuck } from 'redux-typed-ducks/dist';
import { ScheduleConfigVm } from 'services/scheduleConfig';
import actions from './actions';
import { ScheduleConfigState } from './store';

interface EmptyPayload { }

const setState = createDuck<ScheduleConfigState, ScheduleConfigState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const loadScheduleConfig = createDuck<EmptyPayload, EmptyPayload>(
  actions.LOAD_SCHEDULE_CONFIG,
  state => ({
    ...state,
  }),
);


const saveScheduleConfig = createDuck<ScheduleConfigVm, EmptyPayload>(
  actions.SAVE_SCHEDULE_CONFIG,
  state => ({
    ...state,
  }),
);


const setScheduleConfig = createDuck<ScheduleConfigState, ScheduleConfigVm>(
  actions.SET_SCHEDULE_CONFIG,
  (state, payload) => ({
    ...state,
    ...{
      scheduleConfig: payload
    },
  }),
);

export default {
  setState,
  loadScheduleConfig,
  saveScheduleConfig,
  setScheduleConfig
};
