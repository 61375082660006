import requestToApi, { api } from '../baseService';
import { ScheduleRequestVm, ScheduleVm } from './domain';

export async function schedule(payload: ScheduleRequestVm): Promise<boolean> {
  const response = await requestToApi<boolean>(
    '/api/schedules/schedule/',
    'post',
    false,
    payload
  );
  console.log(response);

  return response == true;
}


// base crud

export async function getAll(): Promise<ScheduleVm[]> {
  const response = await api.apiGet<ScheduleVm[]>('/api/schedules/');

  return response.data;
}

export async function get(id: number): Promise<ScheduleVm> {
  const response = await api.apiGet<ScheduleVm>(`/api/schedules/${id}`);

  return response.data;
}

export async function put(model: ScheduleVm): Promise<ScheduleVm> {
  const response = await api.apiPut<ScheduleVm>(`/api/schedules/`, {
    payload: model,
  });

  return response.data;
}

export async function post(model: ScheduleVm): Promise<number> {
  const response = await api.apiPost<number>(`/api/schedules/`, {
    payload: model,
  });

  return response.data;
}

export async function deleteById(id: number): Promise<void> {
  await api.apiDelete(`/api/schedules/${id}`);
}
