const actions = {
  SET_STATE: 'schedule/SET_STATE',
  SET_CURRENT_SCHEDULE: 'schedule/SET_CURRENT_SCHEDULES',

  LOAD_SCHEDULES: 'schedule/LOAD_SCHEDULES',

  CREATE_SCHEDULE: 'schedule/CREATE_SCHEDULE',
  CREATE_CLIENT_LINK: 'schedule/CREATE_CLIENT_LINK',
  SET_SUCCESS_SCHEDULE: 'schedule/SET_SUCCESS_SCHEDULE',
  SET_ERROR_SCHEDULE: 'schedule/SET_ERROR_SCHEDULE',


  ADD_SCHEDULE: 'schedule/ADD_SCHEDULE',
  REMOVE_SCHEDULE: 'schedule/REMOVE_SCHEDULE',

  OPEN_ADD_SCHEDULE_MODAL: 'schedule/OPEN_ADD_SCHEDULE_MODAL',
  CLOSE_ADD_SCHEDULE_MODAL: 'schedule/CLOSE_ADD_SCHEDULE_MODAL',

};

export default actions;
