import React from 'react';
import { BackTop, Layout } from 'antd';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Footer from 'components/LayoutComponents/Footer';
import { SettingStore } from 'redux/settings';

interface Props extends RouteComponentProps<any> {
  isBorderless: boolean;
  isSquaredBorders: boolean;
  isFixedWidth: boolean;
  isMenuShadow: boolean;
  isMenuTop: boolean;
}

const MainLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {


  const leftBar = {
    xs: 0,
    sm: 0,
    md: 1,
    lg: 2,
    xl: 4,
    xxl: 6
  };

  const rightBar = {
    xs: 0,
    sm: 0,
    md: 1,
    lg: 2,
    xl: 4,
    xxl: 6
  };

  const contentBox = {
    xs: 24,
    sm: 24,
    md: 22,
    lg: 20,
    xl: 16,
    xxl: 12
  };

  return (
    <Layout
      style={{ backgroundColor: "#f4f4f4" }}
      className={classNames({
        settings__menuTop: true,
      })}
    >
      <BackTop />
      <Row>
        <Col {...leftBar}></Col>
        <Col {...contentBox}>
          <Layout>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <div className="utils__content">{children}</div>
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Col>
        <Col {...rightBar}></Col>
      </Row>

    </Layout>
  );
};

const mapStateToProps = ({ settingState }: SettingStore) =>
({
  isBorderless: settingState.settings.isBorderless,
  isSquaredBorders: settingState.settings.isSquaredBorders,
  isFixedWidth: settingState.settings.isFixedWidth,
  isMenuShadow: settingState.settings.isMenuShadow,
  isMenuTop: settingState.settings.isMenuTop,
} as Props);

export default connect(
  mapStateToProps,
)(withRouter(MainLayout));
