import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as schedule from '../../services/weekend';
import ManagementReducers from './duck-reducers';

const {
  errorCreating,
  successCreating
} = ManagementReducers;


export function* createPersonalWeekendSaga(action: Action<schedule.WeekendVm>) {
  try {
    yield call(schedule.post, action.payload!);
    yield put(successCreating());
  } catch (e) {
    console.error('personalWeekendSaga', e);
    yield put(errorCreating());
  } 
}

export const Saga = {
  createPersonalWeekendSaga,
};
