import { createDuck } from 'redux-typed-ducks/dist';
import actions from './actions';
import { WeekendState } from './store';
import { WeekenPaginationOptionsResults, WeekendPaginationOptions } from 'services/weekend';

interface EmptyPayload { }

const setState = createDuck<WeekendState, WeekendState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const create = createDuck<WeekendState, EmptyPayload>(
  actions.CREATE_WEEKEND,
  state => ({
    ...state,
    creating: true,
  }),
);

const remove = createDuck<WeekendState, number>(
  actions.REMOVE_WEEKEND,
  state => ({
    ...state,
  }),
);

const successCreating = createDuck<WeekendState, EmptyPayload>(
  actions.SET_SUCCESS_WEEKEND,
  state => ({
    ...state,
    creating: false,
    openCreateModal: false,
  }),
);

const errorCreating = createDuck<WeekendState, EmptyPayload>(
  actions.SET_ERROR_WEEKEND,
  state => ({
    ...state,
    creating: false,
  }),
);

const getWeekendsByOptions = createDuck<WeekendPaginationOptions, EmptyPayload>(
  actions.LOAD_WEEKENDS,
  state => ({
    ...state,
    loading: true,
  }),
);

const setWeekendsAndPaginationOptions = createDuck<WeekendState, WeekenPaginationOptionsResults>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...{
      currentPage: payload.pageNumber,
      totalEmployees: payload.total,
      weekends: payload.items,
      loading: false,
    },
  }),
);

const openCreateModal = createDuck<WeekendState, EmptyPayload>(
  actions.OPEN_ADD_WEEKEND_MODAL,
  state => ({
    ...state,
    openCreateModal: true,
    creatingObject: {
      id: 0,
      title: '',
      date: ''
    },
  }),
);

const closeCreateModal = createDuck<WeekendState, EmptyPayload>(
  actions.CLOSE_ADD_WEEKEND_MODAL,
  state => ({
    ...state,
    openCreateModal: false,
    creatingObject: {
      id: 0,
      title: '',
      date: ''
    },
  }),
);

export default {
  setState,
  create,
  remove,
  successCreating,
  errorCreating,
  getWeekendsByOptions,
  setWeekendsAndPaginationOptions,
  openCreateModal,
  closeCreateModal
};
