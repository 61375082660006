import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import ManagementActions from 'redux/personalWeekend/actions';
import {
  createPersonalWeekendSaga,
} from 'redux/personalWeekend/saga';
import ManagementReducers from 'redux/personalWeekend/duck-reducers';
import { PersonalWeekendState, PersonalWeekendBase } from './store';
import { PersonalWeekendVm } from 'services/personalWeekend';

export * from './store';

const initialState: PersonalWeekendState = {
  personalWeekends: [],
  openCreateModal: false,
  creatingObject: {} as PersonalWeekendVm,
  loading: false,
};

export const Actions = {
  ...ManagementActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(ManagementReducers.create.actionType, createPersonalWeekendSaga),
  ]);
}

export interface WeekendReducerStore extends PersonalWeekendBase {
  personalWeekendState: Reducer<PersonalWeekendState, any>;
}

export const personalWeekendReducer: WeekendReducerStore = {
  personalWeekendState: createReducer(ManagementReducers, initialState),
};
