const configuration = {
  apiUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:44378' // `http://95.217.66.49`,
      : 'https://mi-trato.com',
  apiTokenStorageKey: 'api_token',
  recaptchaKey: '6LeVPXMUAAAAANLJknfyNfJw4gVvbkIhEpp_u4SS',
  itemsPerPage: 10,
  googleClientId:  "873009522992-frsq1nb62cogdha8tme08l297nptvfbb.apps.googleusercontent.com",
  ouathUrlRedirect: "/api/Accounts/OAuthGoogleCallback",
  googleScopes: "email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
};

export default configuration;
