import { objectToUrl } from 'services/basic';
import { api } from '../baseService';
import { EmployeePaginationOptions, EmployeePaginationOptionsResults, EmployeeVm, EmployeeWithSchedulesModelVm } from './domain';

export async function getByOptions(options: EmployeePaginationOptions): Promise<EmployeePaginationOptionsResults> {
  const response = await api.apiGet<EmployeePaginationOptionsResults>(
    '/api/Employees/get-by-options?' + objectToUrl(options),
  );

  return response.data;
}

// base crud

export async function getAllWithSchedules(): Promise<EmployeeWithSchedulesModelVm[]> {
  const response = await api.apiGet<EmployeeWithSchedulesModelVm[]>('/api/Employees/GetAllForCurrentUser');

  return response.data;
}

export async function get(id: number): Promise<EmployeeVm> {
  const response = await api.apiGet<EmployeeVm>(`/api/Employees/${id}`);

  return response.data;
}

export async function put(model: EmployeeVm): Promise<EmployeeVm> {
  const response = await api.apiPut<EmployeeVm>(`/api/Employees/`, {
    payload: model,
  });

  return response.data;
}

export async function post(model: EmployeeVm): Promise<number> {
  const response = await api.apiPost<number>(`/api/Employees/`, {
    payload: model,
  });

  return response.data;
}

export async function deleteById(id: number): Promise<void> {
  await api.apiDelete(`/api/Employees/${id}`);
}
