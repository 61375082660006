import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { menuReducer } from './menu';
import { settingsReducer } from './settings';
import { userReducer } from './user';
import { scheduleConfigReducer } from './scheduleConfig';
import { clientLinksReducer } from './linkManagement';
import { scheduleReducer } from './schedule';
import { weekendReducer } from './weekend';
import { personalWeekendReducer } from './personalWeekend';
import { employeeReducer } from './employee';

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    ...menuReducer,
    ...settingsReducer,
    ...userReducer,
    ...scheduleConfigReducer,
    ...clientLinksReducer,
    ...scheduleReducer,
    ...weekendReducer,
    ...personalWeekendReducer,
    ...employeeReducer
  } as any);
