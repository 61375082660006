import antdData from 'antd/lib/locale-provider/en_US';
import { LocaleModel, Localization } from './model';

const messages: LocaleModel = {
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  'required': 'Required',
  'create': 'Create',
  'loading': 'Create',
  'google': 'Google',

  "linkManagement.page.title": "Links",
  'linkManagement.page.create': 'Create',

  'linkManagement.table.clientName': 'Name',
  'linkManagement.table.clientPhone': 'Phone',
  'linkManagement.table.used': 'Used',
  'linkManagement.table.yes': 'Yes',
  'linkManagement.table.no': 'No',
  'linkManagement.table.linkId': 'Link',
  'linkManagement.table.calendarTitle': 'Calendar title:',
  'linkManagement.table.sessionDuration': 'Session duration:',
  'linkManagement.table.privateNotes': 'Private notes:',
  'linkManagement.table.showDatesTo': 'Show dates to:',
  'linkManagement.table.schedule': 'Schedule:',

  'linkManagement.createLinkModal.validateFailed': 'Validate Failed',
  'linkManagement.createLinkModal.title': 'Title',
  'linkManagement.createLinkModal.privateNotes': 'Private notes',
  'linkManagement.createLinkModal.showDatesTo': 'Show dates to',

  'linkManagement.createLinkModal.titleForCalendar': 'Title for calendar',
  'linkManagement.createLinkModal.clientName': 'Client name',
  'linkManagement.createLinkModal.clientPhone': 'Client phone',
  'linkManagement.createLinkModal.employee': 'Employee',
  'linkManagement.createLinkModal.sessionDuration': 'Session duration(minutes)',
  'linkManagement.createLinkModal.min': 'min',
  'linkManagement.createLinkModal.30min': '30 min',
  'linkManagement.createLinkModal.60min': '1h',
  'linkManagement.createLinkModal.90min': '1h 30min',
  'linkManagement.createLinkModal.create': 'Create',
  'linkManagement.createLinkModal.addLink': 'Add link',


  'employee.createEmployeeModal.title': 'Employee',
  'employee.createEmployeeModal.addEmployee': 'Add employee',
  'employee.createEmployeeModal.create': 'Add employee',
  'employee.createEmployeeModal.name': 'Name',
  'employee.createEmployeeModal.action': 'Action',
  
  'linkPreview.scheduling': 'Scheduling...',
  'linkPreview.hello': 'Hello',
  'linkPreview.chooseDate': 'Choose date',
  'linkPreview.chooseTime': 'Choose time',
  'linkPreview.done': 'Done',
  'linkPreview.ok': 'You have successfully scheduled',
  'linkPreview.error': 'Something went wrong',

  'scheduleConfig.title': 'Links',
  'scheduleConfig.defaultLinkTitle': 'Default link title',
  'scheduleConfig.workTime': 'Work time',
  'scheduleConfig.breakTime': 'Break time',
  'scheduleConfig.sessionDuration': 'Session duration',
  'scheduleConfig.durationBetweenSessions': 'Duration between sessions',
  'scheduleConfig.isNationalWeekendsAreNotWorkingDays': 'Is national weekends are not working days',
  'scheduleConfig.save': 'Save',


  'weekend.title': 'Weekends',
  'weekend.addWeekend': 'Add weekend',
  'weekend.modal.title': 'Title',
  'weekend.modal.date': 'Date',
  'weekend.modal.action': 'Action',

  'user.login.title': 'Wellcome to your deal',
  'user.login.auth': 'Authorization',

  '404.title': 'Page not found',
  '404.description': 'The page is deprecated, deleted, or does not exist at all',
  'menu.home': 'Home',
  'menu.scheduleConfig': 'Schedule Config',
  'menu.linkManagement': 'Link Management',
  'menu.employees': 'Employees',
  'menu.weekends': 'Weekends',
};

export default {
  locale: 'en-US',
  antdData,
  messages,
} as Localization;
