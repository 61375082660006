import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as employee from '../../services/employee';
import ManagementReducers from './duck-reducers';

const {
  errorCreating,
  successCreating,
  closeCreateModal,
  setEmployeesAndPaginationOptions,
  setEmployeesWithSchedules
} = ManagementReducers;

export function* getAllEmployeesByOptions(action: Action<employee.EmployeePaginationOptions>) {
  try {
    const data = (yield call(
      employee.getByOptions, action.payload!
    )) as employee.EmployeePaginationOptionsResults;

    if (data) {
      yield put(setEmployeesAndPaginationOptions(data));
    }
  } catch (e) {
    console.error('employeeSaga', e);
  }
}

export function* getEmployeesWithSchedules() {
  try {
    const data = (yield call(
      employee.getAllWithSchedules
    )) as employee.EmployeeWithSchedulesModelVm[];

    if (data) {
      yield put(setEmployeesWithSchedules(data));
    }
  } catch (e) {
    console.error('employeeSaga', e);
  }
}

export function* createEmployeeSaga(action: Action<employee.EmployeeVm>) {
  try {
    yield call(employee.post, action.payload!);
    yield put(successCreating());
    yield put(closeCreateModal());
  } catch (e) {
    console.error('employeeSaga', e);
    yield put(errorCreating());
  }
}

export function* removeEmployeeSaga(action: Action<number>) {
  try {
    yield call(employee.deleteById, action.payload!);
  } catch (e) {
    console.error('employeeSaga', e);
  }
}

export const Saga = {
  createEmployeeSaga,
  getAllEmployeesByOptions
};
