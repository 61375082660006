import { put, call } from 'redux-saga/effects';
import { getLeftMenuData, getTopMenuData, MenuVm } from 'services/menu';
import MenuReducers from './duck-reducers';

const { setState } = MenuReducers;

export function* GET_DATA() {
  const menuLeftData: MenuVm[] = yield call(getLeftMenuData);
  const menuTopData: MenuVm[] = yield call(getTopMenuData);

  yield put(setState({ menuLeftData, menuTopData }));
}

export const MenuSaga = {
  GET_DATA,
};
