import configuration from './config';

async function getJwtTokenInfo() {
  const token = localStorage.getItem(configuration.apiTokenStorageKey);
  let returnToken = null;
  if (token != null) {
    const base64Url = token.split('.')[1];
    if (base64Url != null) {
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      returnToken = JSON.parse(window.atob(base64));
    }
  }
  return returnToken;
}

export async function getUser() {
  const token = await getJwtTokenInfo();
  let user = null;

  if ((await checkToken(token)) && (await userIsAuthorize())) {
    user = {
      id: token.nameid,
      email: token.email,
      role: token.role,
      departmentId: token.departmentId,
    };
  }

  return user;
}

export async function getUserId() {
  const user = await getUser();

  if (user != null) {
    return user.id;
  }

  return user;
}

export async function getUserEmail() {
  const user = await getUser();

  if (user != null) {
    return user.email;
  }

  return user;
}

export async function getUserRole() {
  const user = await getUser();

  if (user != null) {
    return user.role;
  }

  return user;
}

export async function getUserDepartmentId() {
  const user = await getUser();

  if (user != null) {
    return user.departmentId;
  }

  return user;
}

export async function userIsAuthorize() {
  const token = await getJwtTokenInfo();

  let isAuth = false;

  if (await checkToken(token)) {
    const nowDate = new Date(Date.now());

    const expiredDate = new Date(token.exp * 1000);

    if (expiredDate >= nowDate) {
      isAuth = true;
    }
  }

  return isAuth;
}

async function checkToken(token: string) {
  if (token !== null && token !== undefined) {
    return true;
  }

  return false;
}
