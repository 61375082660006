import { all } from 'redux-saga/effects';
import MenuActions from 'redux/menu/actions';
import MenuReducers from 'redux/menu/duck-reducers';
import { MenuState, MenuStoreBase } from './store';
import { GET_DATA } from 'redux/menu/saga';
import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks';

export * from './store';

const initialState: MenuState = {
  menuLeftData: [],
  menuTopData: [],
};

export const Actions = {
  ...MenuActions,
};

export function* rootSaga() {
  yield all([GET_DATA()]);
}

export interface MenuReducerStore extends MenuStoreBase {
  menuState: Reducer<MenuState, any>;
}

export const menuReducer: MenuReducerStore = {
  menuState: createReducer(MenuReducers, initialState),
};
