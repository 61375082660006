const actions = {
  SET_STATE: 'clientLinks/SET_STATE',
  SET_CURRENT_CLIENT_LINK: 'clientLinks/SET_CURRENT_CLIENT_LINKS',

  LOAD_CLIENT_LINKS: 'clientLinks/LOAD_CLIENT_LINKS',

  ADD_CLIENT_LINK: 'clientLinks/ADD_CLIENT_LINK',
  REMOVE_CLIENT_LINK: 'clientLinks/REMOVE_CLIENT_LINK',

  OPEN_ADD_CLIENT_LINK_MODAL: 'clientLinks/OPEN_ADD_CLIENT_LINK_MODAL',
  CLOSE_ADD_CLIENT_LINK_MODAL: 'clientLinks/CLOSE_ADD_CLIENT_LINK_MODAL',
  CREATE_CLIENT_LINK: 'clientLinks/CREATE_CLIENT_LINK',

  LOAD_LINK_PREVIEW: 'clientLinks/LOAD_LINK_PREVIEW',
  SET_LINK_PREVIEW: 'clientLinks/SET_LINK_PREVIEW',
};

export default actions;
