import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as linkManagement from '../../services/linkManagement';
import LinksManagementReducers from './duck-reducers';

const {
  getClientLinksByOptions,
  setCurrentClientLinksForUser,
  setLinkPreview,
  closeCreateClientLinkModal,
} = LinksManagementReducers;

export function* getAllClientLinksByOptions(action: Action<linkManagement.ClientLinkPaginationOptions>) {
  try {
    const data = (yield call(
      linkManagement.getByOptions, action.payload!
    )) as linkManagement.ClientLinkVm[];

    if (data) yield put(setCurrentClientLinksForUser(data));
  } catch (e) {
    console.error('linkManagementSaga', e);
  }
}

export function* getLinkPreview(action: Action<string>) {
  try {
    const data = (yield call(
      linkManagement.getLinkByLinkId, action.payload!
    )) as linkManagement.LinkPreviewVm;

    if (data) yield put(setLinkPreview(data));
  } catch (e) {
    console.error('linkManagementSaga', e);
  }
}

export function* createClientLink(action: Action<linkManagement.ClientLinkVm>) {
  try {
    const data: number = yield call(linkManagement.post, action.payload!);
    if (data) yield getClientLinksByOptions();
  } catch (e) {
    console.error('linkManagementSaga', e);
  } finally {
    yield put(closeCreateClientLinkModal());
  }
}

export function* removeCientLink(action: Action<number>) {
  try {
    yield call(linkManagement.deleteById, action.payload!);
    yield getClientLinksByOptions();
  } catch (e) {
    console.error('linkManagementSaga', e);
  } finally {
    yield put(closeCreateClientLinkModal());
  }
}

export const Saga = {
  getAllClientLinksByOptions,
  createClientLink,
  removeCientLink
};
