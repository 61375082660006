import { store as reduxStore } from 'index'
import store from 'store';
import SettingsReducers, { SettingItem } from './duck-reducers';
import { Action } from 'redux-typed-ducks/dist';

const { changeSetting } = SettingsReducers;

export function* Setup() {
  // load settings from url on app load
  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = window.innerWidth < 768;
    const prevState = store.get('app.settings.isMobileView');
    if (currentState !== prevState || load) {
      reduxStore.dispatch(
        changeSetting({
          setting: 'isMobileView',
          value: currentState,
        })
      );
    }
  };

  yield isMobileView(true);

  yield window.addEventListener('resize', () => {
    isMobileView();
  });
}

export function* chnageSetting(action: Action<SettingItem>) {
  yield store.set(`app.settings.${action.payload!.setting}`, action.payload!.value)
}

export const SettingsSaga = {
  Setup,
  chnageSetting
};
