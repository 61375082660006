import { createDuck } from 'redux-typed-ducks/dist';
import { ClientLinkVm, LinkPreviewVm } from 'services/linkManagement';
import actions from './actions';
import { ClientLinksState } from './store';

interface EmptyPayload { }

const setState = createDuck<ClientLinksState, ClientLinksState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const getClientLinksByOptions = createDuck<ClientLinksState, EmptyPayload>(
  actions.LOAD_CLIENT_LINKS,
  state => ({
    ...state,
    loading: true,
  }),
);

const setCurrentClientLinksForUser = createDuck<ClientLinksState, ClientLinkVm[]>(
  actions.SET_CURRENT_CLIENT_LINK,
  (state, payload) => ({
    ...state,
    clientLinks: payload,
    loading: false,
  }),
);

const setLinkPreview = createDuck<ClientLinksState, LinkPreviewVm>(
  actions.SET_LINK_PREVIEW,
  (state, payload) => ({
    ...state,
    linkPreview: payload,
    loading: false,
  }),
);

const loadLinkPreview = createDuck<ClientLinksState, EmptyPayload>(
  actions.LOAD_LINK_PREVIEW,
  (state) => ({
    ...state,
    loading: true,
  }),
);

const openCreateClientLinkModal = createDuck<ClientLinksState, EmptyPayload>(
  actions.OPEN_ADD_CLIENT_LINK_MODAL,
  state => ({
    ...state,
    openCreateModal: true,
    creatingClientLink: {
      id: 0,
      title: '',
      privateNotes: '',
      showDatesTo: '',
      clientName: '',
      clientPhone: '',
      employeeId: 0,
      used: false,
      userId: 0,
      sessionDuration: ''
    },
  }),
);

const closeCreateClientLinkModal = createDuck<ClientLinksState, EmptyPayload>(
  actions.CLOSE_ADD_CLIENT_LINK_MODAL,
  state => ({
    ...state,
    openCreateModal: false,
    creatingClientLink: {
      id: 0,
      title: '',
      privateNotes: '',
      clientName: '',
      clientPhone: '',
      showDatesTo: '',
      userId: 0,
      used: false,
      employeeId: 0,
      sessionDuration: ''
    },
  }),
);

const createClientLink = createDuck<ClientLinkVm, EmptyPayload>(
  actions.CREATE_CLIENT_LINK,
  state => ({
    ...state,
  }),
);

const removeClientLink = createDuck<ClientLinkVm, EmptyPayload>(
  actions.REMOVE_CLIENT_LINK,
  state => ({
    ...state,
  }),
);

export default {
  setState,
  setLinkPreview,
  setCurrentClientLinksForUser,
  getClientLinksByOptions,
  openCreateClientLinkModal,
  closeCreateClientLinkModal,
  createClientLink,
  removeClientLink,
  loadLinkPreview
};
