import { Action } from 'redux-typed-ducks/dist';
import { call, put } from 'redux-saga/effects';
import * as weekend from '../../services/weekend';
import ManagementReducers from './duck-reducers';

const {
  errorCreating,
  successCreating,
  setWeekendsAndPaginationOptions
} = ManagementReducers;

export function* getAllWeekendByOptions(action: Action<weekend.WeekendPaginationOptions>) {
  try {
    const data = (yield call(
      weekend.getByOptions, action.payload!
    )) as weekend.WeekenPaginationOptionsResults;

    if (data) {
      yield put(setWeekendsAndPaginationOptions(data));
    }
  } catch (e) {
    console.error('weekendSaga', e);
  }
}

export function* createWeekendSaga(action: Action<weekend.WeekendVm>) {
  try {
    yield call(weekend.post, action.payload!);
    yield put(successCreating());
  } catch (e) {
    console.error('weekendSaga', e);
    yield put(errorCreating());
  } 
}

export function* removeWeekendSaga(action: Action<number>) {
  try {
    yield call(weekend.deleteById, action.payload!);

  } catch (e) {
    console.error('weekendSaga', e);
  }
}

export const Saga = {
  getAllWeekendByOptions,
  createWeekendSaga,
  removeWeekendSaga
};
