const actions = {
  SET_STATE: 'employee/SET_STATE',
  SET_CURRENT_EMPLOYEE: 'employee/SET_CURRENT_EMPLOYEES',
  SET_EMPLOYEES_AND_PAGINATION_OPTIONS: 'employee/SET_EMPLOYEES_AND_PAGINATION_OPTIONS',
  

  LOAD_EMPLOYEES: 'employee/LOAD_EMPLOYEES',
  LOAD_EMPLOYEES_WITH_SCHEDULES: 'employee/LOAD_EMPLOYEES_WITH_SCHEDULES',

  CREATE_EMPLOYEE: 'employee/CREATE_EMPLOYEE',
  SET_SUCCESS_EMPLOYEE: 'employee/SET_SUCCESS_EMPLOYEE',
  SET_ERROR_EMPLOYEE: 'employee/SET_ERROR_EMPLOYEE',


  ADD_EMPLOYEE: 'employee/ADD_EMPLOYEE',
  REMOVE_EMPLOYEE: 'employee/REMOVE_EMPLOYEE',

  OPEN_ADD_EMPLOYEE_MODAL: 'employee/OPEN_ADD_EMPLOYEE_MODAL',
  CLOSE_ADD_EMPLOYEE_MODAL: 'employee/CLOSE_ADD_EMPLOYEE_MODAL',

};

export default actions;
