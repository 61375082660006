import { api } from '../baseService';
import { ScheduleConfigVm } from './domain';


export async function getUserConfig(): Promise<ScheduleConfigVm> {
  const response = await api.apiGet<ScheduleConfigVm>('/api/scheduleconfigs/get-user-schedule-config');

  return response.data;
}

export async function updateUserConfig(model: ScheduleConfigVm): Promise<any> {
  const response = await api.apiPut<ScheduleConfigVm>(`/api/scheduleconfigs`, {
    payload: model,
  });

  return response;
}
