import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import english from '../../../locales/en-US';
import ukrainian from '../../../locales/uk-UA';
import spanish from '../../../locales/es-ES';
import { SettingStore } from 'redux/settings';

const locales = {
  'en-US': english,
  'uk-UA': ukrainian,
  'es-ES': spanish
};

export interface LocaleSettings {
  locale: string;
}

interface Props {
  settings: LocaleSettings;
}

const Localization: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  settings: { locale },
}) => {
  const currentLocale = locales[locale];

  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ settingState }: SettingStore) =>
  ({
    settings: settingState.settings,
  } as Props);

export default connect(mapStateToProps)(Localization);
