import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import SettingsActions from 'redux/settings/actions';
import SettingsReducers from 'redux/settings/duck-reducers';
import { SettingState, SettingStoreBase } from './store';
import { Setup } from 'redux/settings/saga';
import { SettingsVm } from 'services/settings/domain';
import store from 'store';
import { chnageSetting } from 'redux/settings/saga';
export * from './store';

const STORED_SETTINGS = (storedSettings: SettingsVm) => {
  const settings = storedSettings;
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings;
};

const initialState: SettingState = {
  settings: {
    ...STORED_SETTINGS({
      isMobileView: false,
      isMobileMenuOpen: false,
      isLightTheme: false,
      isMenuTop: false,
      isMenuCollapsed: false,
      isBorderless: false,
      isSquaredBorders: false,
      isFixedWidth: true,
      isMenuShadow: false,
      locale: 'es-ES',
    }),
  } as SettingsVm,
};

export const Actions = {
  ...SettingsActions,
};

export function* rootSaga() {
  yield all([
    Setup(),
    takeEvery(SettingsReducers.changeSetting.actionType, chnageSetting),
  ]);
}

export interface SettingReducerStore extends SettingStoreBase {
  settingState: Reducer<SettingState, any>;
}

export const settingsReducer: SettingReducerStore = {
  settingState: createReducer(SettingsReducers, initialState),
};
