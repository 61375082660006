import { createDuck } from 'redux-typed-ducks/dist';
import actions from './actions';
import { EmployeeState } from './store';
import { EmployeePaginationOptions, EmployeePaginationOptionsResults, EmployeeWithSchedulesModelVm } from 'services/employee';

interface EmptyPayload { }

const setState = createDuck<EmployeeState, EmployeeState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const setEmployeesAndPaginationOptions = createDuck<EmployeeState, EmployeePaginationOptionsResults>(
  actions.SET_EMPLOYEES_AND_PAGINATION_OPTIONS,
  (state, payload) => ({
    ...state,
    ...{
      currentPage: payload.pageNumber,
      totalEmployees: payload.total,
      employees: payload.items,
      loading: false
    },
  }),
);

const create = createDuck<EmployeeState, EmptyPayload>(
  actions.CREATE_EMPLOYEE,
  state => ({
    ...state,
  }),
);

const remove = createDuck<EmployeeState, number>(
  actions.REMOVE_EMPLOYEE,
  state => ({
    ...state,
  }),
);

const openCreateModal = createDuck<EmployeeState, EmptyPayload>(
  actions.OPEN_ADD_EMPLOYEE_MODAL,
  state => ({
    ...state,
    openCreateModal: true
  }),
);


const closeCreateModal = createDuck<EmployeeState, EmptyPayload>(
  actions.CLOSE_ADD_EMPLOYEE_MODAL,
  state => ({
    ...state,
    openCreateModal: false
  }),
);

const successCreating = createDuck<EmployeeState, EmptyPayload>(
  actions.SET_SUCCESS_EMPLOYEE,
  state => ({
    ...state,
    creating: false,
    creatingSuccess: true
  }),
);

const errorCreating = createDuck<EmployeeState, EmptyPayload>(
  actions.SET_ERROR_EMPLOYEE,
  state => ({
    ...state,
    creating: false,
    creatingSuccess: false
  }),
);

const getEmployeesByOptions = createDuck<EmployeePaginationOptions, EmptyPayload>(
  actions.LOAD_EMPLOYEES,
  state => ({
    ...state,
    loading: true,
  }),
);

const getEmployeesWithSchedules = createDuck<EmployeeState, EmptyPayload>(
  actions.LOAD_EMPLOYEES_WITH_SCHEDULES,
  state => ({
    ...state,
    loadingEmployeesWithSchedules: true,
  }),
);

const setEmployeesWithSchedules = createDuck<EmployeeState, EmployeeWithSchedulesModelVm[]>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...{
      employeesWithSchedules: payload,
      loadingEmployeesWithSchedules: false
    },
  }),
);

export default {
  setState,
  create,
  remove,
  setEmployeesAndPaginationOptions,
  openCreateModal,
  closeCreateModal,
  getEmployeesByOptions,
  successCreating,
  errorCreating,
  getEmployeesWithSchedules,
  setEmployeesWithSchedules
};
