import antdData from 'antd/lib/locale-provider/es_ES';
import { LocaleModel, Localization } from './model';

const messages: LocaleModel = {
  "topBar.profileMenu.hello": "Hola",
  "topBar.profileMenu.role": "Rol",
  "topBar.profileMenu.email": "Correo electrónico",
  "topBar.profileMenu.phone": "Teléfono",
  "topBar.profileMenu.editProfile": "Editar perfil",
  "topBar.profileMenu.logout": "Cerrar sesión",

  "required": "Requerido",
  "create": "Crear",
  "loading": "Cargando",
  "google": "Google",

  "linkManagement.page.title": "Enlaces",
  "linkManagement.page.create": "Crear",

  "linkManagement.table.clientName": "Nombre",
  "linkManagement.table.clientPhone": "Teléfono",
  "linkManagement.table.used": "Usado",
  "linkManagement.table.yes": "Sí",
  "linkManagement.table.no": "No",
  "linkManagement.table.linkId": "Enlace",
  "linkManagement.table.calendarTitle": "Título del calendario:",
  "linkManagement.table.sessionDuration": "Duración de la sesión:",
  "linkManagement.table.privateNotes": "Notas privadas:",
  "linkManagement.table.showDatesTo": "Mostrar fechas hasta:",
  "linkManagement.table.schedule": "Horario:",

  "linkManagement.createLinkModal.validateFailed": "Validación fallida",
  "linkManagement.createLinkModal.title": "Título",
  "linkManagement.createLinkModal.privateNotes": "Notas privadas",
  "linkManagement.createLinkModal.showDatesTo": "Mostrar fechas hasta",

  "linkManagement.createLinkModal.titleForCalendar": "Título para el calendario",
  "linkManagement.createLinkModal.clientName": "Nombre del cliente",
  "linkManagement.createLinkModal.clientPhone": "Teléfono del cliente",
  "linkManagement.createLinkModal.employee": "Empleado",
  "linkManagement.createLinkModal.sessionDuration": "Duración de la sesión (minutos)",
  "linkManagement.createLinkModal.min": "min",
  "linkManagement.createLinkModal.30min": "30 min",
  "linkManagement.createLinkModal.60min": "1h",
  "linkManagement.createLinkModal.90min": "1h 30min",
  "linkManagement.createLinkModal.create": "Crear",
  "linkManagement.createLinkModal.addLink": "Agregar enlace",

  "employee.createEmployeeModal.title": "Empleado",
  "employee.createEmployeeModal.addEmployee": "Agregar empleado",
  "employee.createEmployeeModal.create": "Agregar empleado",
  "employee.createEmployeeModal.name": "Nombre",
  "employee.createEmployeeModal.action": "Acción",

  "linkPreview.scheduling": "Programación...",
  "linkPreview.hello": "Hola",
  "linkPreview.chooseDate": "Elegir fecha",
  "linkPreview.chooseTime": "Elegir hora",
  "linkPreview.done": "Hecho",
  "linkPreview.ok": "Has reservado con éxito una cita",
  "linkPreview.error": "Algo salió mal",
  
  'scheduleConfig.title': 'Enlaces',
  'scheduleConfig.defaultLinkTitle': 'Título predeterminado del enlace',
  'scheduleConfig.workTime': 'Horario de trabajo',
  'scheduleConfig.breakTime': 'Tiempo de descanso',
  'scheduleConfig.sessionDuration': 'Duración de la sesión',
  'scheduleConfig.durationBetweenSessions': 'Duración entre sesiones',
  'scheduleConfig.isNationalWeekendsAreNotWorkingDays': 'Los fines de semana nacionales no son días laborables',
  'scheduleConfig.save': 'Guardar',

  "weekend.title": "Fines de semana",
  "weekend.addWeekend": "Agregar fin de semana",
  "weekend.modal.title": "Título",
  "weekend.modal.date": "Fecha",
  "weekend.modal.action": "Acción",

  "user.login.title": "Bienvenido a tu trato",
  "user.login.auth": "Autorización",

  "404.title": "Página no encontrada",
  "404.description": "La página está obsoleta, eliminada o no existe en absoluto",

  'menu.home': 'Inicio',
  'menu.scheduleConfig': 'Configuración de Horario',
  'menu.linkManagement': 'Gestión de Enlaces',
  'menu.employees': 'Empleados',
  'menu.weekends': 'Fines de Semana',
};


export default {
  locale: 'es-ES',
  antdData,
  messages,
} as Localization;
