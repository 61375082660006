import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import Loader from 'components/LayoutComponents/Loader';
import PublicLayout from './Public';
import LoginLayout from './Login';
import MainLayout from './Main';
import { UserStore } from 'redux/user';
import { UserProfileVm } from 'services/users';

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
};

interface Props extends RouteComponentProps<any> {
  user: UserProfileVm;
}

const IndexLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  location: { pathname, search },
  location,
  user,
}) => {
  const [previousPath, setPreviousPath] = useState<string>('');

  useEffect(() => {
    if (location.pathname !== previousPath) {
      window.scrollTo(0, 0);
      // setPreviousPath(location);
    }
  }, [location]);

  // NProgress Management
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    NProgress.start();
  }

  setTimeout(() => {
    NProgress.done();
    setPreviousPath(currentPath);
  }, 300);

  // Layout Rendering
  const getLayout = () => {
    if (/^\/l(?=\/|$)/i.test(pathname)) {
      return 'public';
    }

    if (pathname == "/404") {
      return 'public';
    }

    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login';
    }

    return 'main';
  };

  const Container = Layouts[getLayout()];
  const isUserAuthorized = user.authorized;
  const isUserLoading = user.loading;
  const isLoginLayout = getLayout() === 'login';
  const isPublicLayout = getLayout() === 'public';

  const BootstrappedLayout = () => {
    if (isPublicLayout) {
      return <Container>{children}</Container>;
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <Loader />;
    }

    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/user/login" />;
    }

    // redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && isUserAuthorized) {
      return <Redirect to="/" />;
    }

    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <Fragment>
      <Helmet titleTemplate="Mi trato | %s" title="Home" />
      {BootstrappedLayout()}
    </Fragment>
  );
};

const mapStateToProps = ({ userState }: UserStore) =>
({
  user: userState.user,
} as Props);

export default connect(mapStateToProps)(withRouter(IndexLayout));
