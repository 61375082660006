import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import { UserProfileVm } from 'services/users';
import { UserState, UserStoreBase } from './store';
import UserActions from './duck-reducers';
import {
  getCurrentUserSaga,
  logoutSaga,
  getTokenByAuthCode,
} from './saga';

export * from './store';

const initialState: UserState = {
  user: {
    name: '',
    role: '',
    email: '',
    authorized: false,
    loading: false
  } as UserProfileVm,
};

export const Actions = {
  ...UserActions,
};

export interface UserReducerStore extends UserStoreBase {
  userState: Reducer<UserState, any>;
}

export function* rootSaga() {
  yield all([
    takeEvery(UserActions.getCurrentUser.actionType, getCurrentUserSaga),
    takeEvery(UserActions.getTokenByAuthCode.actionType, getTokenByAuthCode),
    takeEvery(UserActions.logoutUser.actionType, logoutSaga),
    getCurrentUserSaga(),
  ]);
}

export const userReducer: UserReducerStore = {
  userState: createReducer(UserActions, initialState),
};
