import Actions, { SettingItem } from 'redux/settings/duck-reducers';
import MenuTop from './MenuTop';
import { AnyAction } from 'redux';
import React, { Dispatch } from 'react';
import { SettingStore } from 'redux/settings';
import { Row, Col, Drawer } from 'antd';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import styles from './style.module.scss';
import { MenuUnfoldOutlined } from '@ant-design/icons';

interface Props extends RouteComponentProps<any> {
  isMenuTop: boolean;
  isMobileMenuOpen: boolean;
  isMobileView: boolean;
  isLightTheme: boolean;
  changeSetting: (setting: SettingItem) => void;
}

export const AppMenu = (props: Props) => {
  const {
    isMobileMenuOpen,
    isMobileView,
    isLightTheme,
    changeSetting,
  } = props;

  const toggleOpen = () => {
    document
      .querySelector('#root')!
      .setAttribute(
        'style',
        !isMobileMenuOpen ? ' width: 100%; height: 100%;' : '',
      );

    changeSetting({
      setting: 'isMobileMenuOpen',
      value: !isMobileMenuOpen,
    });
  };

  const logoBox = {
    xs: 9,
    sm: 9,
    md: 6,
    lg: 6,
    xl: 6,
    xxl: 6
  };
  const menuBox = {
    xs: 0,
    sm: 0,
    md: 10,
    lg: 10,
    xl: 10,
    xxl: 10
  };
  const profileBox = {
    xs: 12,
    sm: 12,
    md: 8,
    lg: 8,
    xl: 8,
    xxl: 8
  };


  const BootstrappedMenu = () => {
    const mobileDrawwer =
      <Drawer
        // level={null}
        placement="left"
        open={isMobileMenuOpen}
        onClose={toggleOpen}
        className={isLightTheme ? 'drawer-light' : ''}
      >
        <MenuTop />
      </Drawer>;

    return (
      <>
        {isMobileView && mobileDrawwer}
        <Row className='bg-white pt-3'>
          {
            isMobileView &&
            <Col span={3} className="d-flex justify-content-center" onClick={toggleOpen}>
              <MenuUnfoldOutlined className='my-auto' style={{ fontSize: '1.8rem' }} />
            </Col>
          }
          <Col {...logoBox} className="d-flex">
            <div className={styles.logo}>
              <div className={styles.logoContainer}>
                <img src="resources/images/logo-inverse.png" alt="logo" />
              </div>
            </div>
          </Col>
          {!isMobileView && <Col {...menuBox}>
            <MenuTop />
          </Col>}
          <Col {...profileBox} className="d-flex justify-content-end pr-1">
            <ProfileMenu />
          </Col>
        </Row>
      </>)
  };

  return BootstrappedMenu();
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  changeSetting: (setting: SettingItem) =>
    dispatch(Actions.changeSetting(setting)),
});

const mapStateToProps = ({ settingState }: SettingStore) =>
({
  isMenuTop: settingState.settings.isMenuTop,
  isMobileMenuOpen: settingState.settings.isMobileMenuOpen,
  isMobileView: settingState.settings.isMobileView,
  isLightTheme: settingState.settings.isLightTheme,
} as Props);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppMenu));
