import { createDuck } from 'redux-typed-ducks/dist';
import actions from './actions';
import { PersonalWeekendState } from './store';

interface EmptyPayload { }

const setState = createDuck<PersonalWeekendState, PersonalWeekendState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const create = createDuck<PersonalWeekendState, EmptyPayload>(
  actions.CREATE_PERSONAL_WEEKEND,
  state => ({
    ...state,
    scheduling: true,
  }),
);

const successCreating = createDuck<PersonalWeekendState, EmptyPayload>(
  actions.SET_SUCCESS_PERSONAL_WEEKEND,
  state => ({
    ...state,
    scheduling: false,
    schedulingSuccess: true
  }),
);

const errorCreating = createDuck<PersonalWeekendState, EmptyPayload>(
  actions.SET_ERROR_PERSONAL_WEEKEND,
  state => ({
    ...state,
    scheduling: false,
    schedulingSuccess: false
  }),
);

export default {
  setState,
  create,
  successCreating,
  errorCreating,
};
