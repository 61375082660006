import requestToApi, { api, ApiCallParams } from '../baseService';
import { AuthUserVm, UserProfileVm } from './domain';
import configuration from '../config';

export interface PostUserLoginRequest {
  authCode: string;
}

export interface PostUserRegisterRequest extends PostUserLoginRequest {
  phoneNumber: string;
  rePassword: string;
}

export async function getTokenByAuthCodeRequest(
  params: ApiCallParams<PostUserLoginRequest>,
): Promise<boolean> {
  const result = await requestToApi<AuthUserVm>(
    '/api/accounts/get-token-by-auth-code?authCode=' + params.payload.authCode,
    'get',
    false,
  );
  if (result) {
    if (result != null && result.token != null) {
      localStorage.setItem(configuration.apiTokenStorageKey, result.token);
      return true;
    }
  }

  return false;
}

export async function refreshToken(): Promise<boolean> {
  const response = await api.apiGet<AuthUserVm>('/api/Accounts/refresh-token');
  const { data } = response;

  if (data) {
    if (data != null && data.token != null) {
      localStorage.setItem(configuration.apiTokenStorageKey, data.token);
      return true;
    }
  }

  return false;
}

export async function currentAccount(): Promise<UserProfileVm> {
  const response = await api.apiGet<UserProfileVm>('/api/Accounts/me');

  return response.data;
}

export async function logout() {
  localStorage.removeItem(configuration.apiTokenStorageKey);
  return true;
}
