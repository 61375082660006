import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import ManagementActions from 'redux/weekend/actions';
import {
  createWeekendSaga,
  removeWeekendSaga,
  getAllWeekendByOptions
} from 'redux/weekend/saga';
import ManagementReducers from 'redux/weekend/duck-reducers';
import { WeekendState, WeekendBase } from './store';
import { WeekendVm } from 'services/weekend';

export * from './store';

const initialState: WeekendState = {
  weekends: [],
  openCreateModal: false,
  creatingObject: {} as WeekendVm,
  loading: false,
  creating: false
};

export const Actions = {
  ...ManagementActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(ManagementReducers.create.actionType, createWeekendSaga),
    takeEvery(ManagementReducers.remove.actionType, removeWeekendSaga),
    takeEvery(ManagementReducers.getWeekendsByOptions.actionType, getAllWeekendByOptions),
  ]);
}

export interface WeekendReducerStore extends WeekendBase {
  weekendState: Reducer<WeekendState, any>;
}

export const weekendReducer: WeekendReducerStore = {
  weekendState: createReducer(ManagementReducers, initialState),
};
