import { MenuVm } from '.';

export const menu: MenuVm[] = [
  {
    title: 'Home',
    key: 'home',
    url: '/',
  },
  {
    title: 'Schedule Config',
    key: 'scheduleConfig',
    url: '/scheduleConfig',
  },
  {
    title: 'Link Management',
    key: 'linkManagement',
    url: '/linkManagement',
  },
  {
    title: 'Employees',
    key: 'employees',
    url: '/employees',
  },
  {
    title: 'Weekends',
    key: 'weekends',
    url: '/weekends',
  },
];

export async function getTopMenuData(): Promise<MenuVm[]> {
  return menu;
}

export async function getLeftMenuData(): Promise<MenuVm[]> {
  return menu;
}
