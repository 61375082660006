import { all } from 'redux-saga/effects';
import * as menu from './menu';
import * as user from './user';
import * as categories from './scheduleConfig';
import * as settings from './settings';
import * as linkManagement from './linkManagement';
import * as schedule from './schedule';
import * as employee from './employee';
import * as weekend from './weekend';
import * as personalWeekend from './personalWeekend';

export default function* rootSaga() {
  yield all([
    menu.rootSaga(),
    settings.rootSaga(),
    user.rootSaga(),
    categories.rootSaga(),
    linkManagement.rootSaga(),
    schedule.rootSaga(),
    employee.rootSaga(),
    weekend.rootSaga(),
    personalWeekend.rootSaga()
  ]);
}
