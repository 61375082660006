import { createDuck } from 'redux-typed-ducks/dist';
import actions from './actions';
import { SettingState } from './store';

export interface SettingItem {
  setting: string;
  value: any;
}

const setState = createDuck<SettingState, SettingState>(
  actions.SET_STATE,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

const changeSetting = createDuck<SettingState, SettingItem>(
  actions.CHANGE_SETTING,
  (state, payload) => ({
    ...state,
    ...{
      settings: {
        ...state.settings,
        ...{
          [payload.setting]: payload.value,
        },
      },
    },
  }),
);

export default {
  setState,
  changeSetting,
};
