import { Reducer } from 'react';
import { createReducer } from 'redux-typed-ducks/dist';
import { all, takeEvery } from 'redux-saga/effects';
import ManagementActions from 'redux/schedule/actions';
import {
  createScheduleSaga,
} from 'redux/schedule/saga';
import ManagementReducers from 'redux/schedule/duck-reducers';
import { ScheduleState, ScheduleBase as ClientLinksBase } from './store';
import { ScheduleVm } from 'services/schedule';

export * from './store';

const initialState: ScheduleState = {
  schedules: [],
  openCreateModal: false,
  creatingSchedule: {} as ScheduleVm,
  loading: false,
  scheduling: false,
};

export const Actions = {
  ...ManagementActions,
};

export function* rootSaga() {
  yield all([
    takeEvery(ManagementReducers.createSchedule.actionType, createScheduleSaga),
  ]);
}

export interface ClientLinksReducerStore extends ClientLinksBase {
  scheduleState: Reducer<ScheduleState, any>;
}

export const scheduleReducer: ClientLinksReducerStore = {
  scheduleState: createReducer(ManagementReducers, initialState),
};
